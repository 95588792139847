import { useRef } from "react";
import PropTypes from "prop-types";
import { h } from "h";
import c from "classnames";
import validator from "../util/validator";
import { div } from "tags";
import {
  form,
  ManagedForm,
  FormError,
  SubmitButton,
  TextField,
  EmailField,
  CreatePasswordField
} from "../form";

const AccountFields = props => {
  const { formError, fields, form, loading, onSubmit } = props;
  const submitHandler = (values) => {
    onSubmit({ ...values, token: null });
  }
  const className = c("create_account", { loading });
  return h(ManagedForm, { form, className, onSubmit: submitHandler }, [
    div(".fieldgroup", [
      formError && h(FormError, { text: Object.entries(formError)[0].join(" ") }),
      div(".row", [
        h(TextField, { width: 6, label: "First Name", field: fields.firstName }),
        h(TextField, { width: 6, label: "Last Name", field: fields.lastName })
      ]),
      div(".row", [
        h(EmailField, { label: "Email Address", form, field: fields.email })
      ]),
      div(".row", [
        h(TextField, { width: 6, label: "Username", field: fields.username }),
        h(CreatePasswordField, {
          width: 6,
          label: "Password",
          field: fields.password,
          show: fields.showPassword
        })
      ])
    ]),
    div(".fieldgroup.buttons", [h(SubmitButton, { form, text: "Continue" })])
  ]);
};

const fields = [
  "firstName",
  "lastName",
  "email",
  "username",
  "password",
  "showPassword"
];

export function validate(values) {
  return validator(values, {
    firstName: { presence: true },
    lastName: { presence: true },
    email: { presence: true, email: true },
    username: {
      presence: true,
      length: { minimum: 4, maximum: 30 },
      alphanumeric: true
    },
    password: {
      password: true
    }
  });
}

export default form(fields, validate)(AccountFields);
