import PropTypes from "prop-types";
import React, { useReducer, useEffect } from "react";
import TosForm from "checkout/components/TosForm";
import VerificationCodeForm from "email_verification/VerificationCodeForm";
import AccountFields from "checkout/components/AccountFields";
import { sendVerificationEmailNew, resendVerificationEmail, verifyEmail } from "../email_verification/requests";


const initialState = {
  step: "account_form",
  formValues: {},
  formErrors: {
    account: null,
    verification: null,
  },
  loading: false,
  header: "Create Account",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_STEP":
      return { ...state, step: action.step };
    case "SET_FIELDS":
      return {
        ...state,
        formValues: {
          ...action.fields,
        },
      };
    case "UPDATE_FIELDS":
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.fields,
        },
      };
    case "SET_FORM_ERRORS":
      return { ...state, formErrors: { ...state.formErrors, ...action.formErrors } };
    case "SET_LOADING":
      return { ...state, loading: action.loading };
    case "SET_HEADER":
      return { ...state, header: action.header };
    default:
      return state;
  }
}

function CreateAccountWizard(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const headerSetter = props.setHeader
    ? (header) => props.setHeader(header)
    : (header) => dispatch({ type: "SET_HEADER", header });
  useEffect(() => {
    setHeader(state.step, headerSetter);
    if (props.setStep) {
      props.setStep(state.step);
    }
  }, [state.step]);
  return renderStep(props, state, dispatch);
}

CreateAccountWizard.defaultProps = {
  sendVerificationEmailNew,
  resendVerificationEmail,
  verifyEmail,
};

function renderStep(props, state, dispatch) {
  switch (state.step) {
    case "account_form":
      return renderAccountFields(props, state, dispatch);
    case "verification_form":
      return renderVerificationCodeForm(props, state, dispatch);
    case "tos_form":
      return renderTosForm(props, state, dispatch);
    default:
      throw `Unknown Step ${state.step}`;
  }
}

function setHeader(step, setter) {
  switch (step) {
    case "account_form":
      setter("Create Account");
      break;
    case "verification_form":
      setter("Confirm your email address");
      break;
    case "tos_form":
      setter("Terms of Service");
      break;
  }
}

function renderAccountFields(props, state, dispatch) {
  const { verificationRequired } = props;
  const onSubmit = async (values) => {
    const { firstName, lastName, email, username, password, token } = values;
    const formValues = {
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      password,
      token,
    };
    dispatch({ type: "SET_FIELDS", fields: formValues });
    dispatch({ type: "SET_FORM_ERRORS", formErrors: null });
    if (verificationRequired) {
      dispatch({ type: "SET_LOADING", loading: true });
      try {
        await props.sendVerificationEmailNew({
          username: values.username,
          email: values.email,
        });
        dispatch({ type: "SET_STEP", step: "verification_form" });
      } catch (e) {
        dispatch({ type: "SET_FORM_ERRORS", formErrors: { account: e } });
      } finally {
        dispatch({ type: "SET_LOADING", loading: false });
      }
    } else {
      dispatch({ type: "SET_STEP", step: "tos_form" });
    }
  };

  const initialValues = {
    firstName: state.formValues.first_name,
    lastName: state.formValues.last_name,
    email: state.formValues.email,
    username: state.formValues.username,
    password: state.formValues.password,
    token: state.formValues.token,
  };
  return (
    <AccountFields
      onSubmit={onSubmit}
      formError={state.formErrors.account}
      initialValues={initialValues}
    />
  );
}

function renderVerificationCodeForm(props, state, dispatch) {
  const email = state.formValues.email;
  const onSubmit = async (values) => {
    dispatch({ type: "SET_LOADING", loading: true });
    try {
      await props.verifyEmail({
        email: state.formValues.email,
        code: values.code,
      });
      dispatch({ type: "UPDATE_FIELDS", fields: { code: values.code } });
      dispatch({ type: "SET_STEP", step: "tos_form" });
    } catch (e) {
      dispatch({ type: "SET_FORM_ERRORS", formErrors: { verification: e } });
    } finally {
      dispatch({ type: "SET_LOADING", loading: false });
    }
  };

  const onBack = () => {
    dispatch({ type: "SET_STEP", step: "account_form" });
    dispatch({ type: "SET_FORM_ERRORS", formErrors: { verification: null } });
  };

  const onResend = () => {
    dispatch({ type: "SET_FORM_ERRORS", formErrors: { verification: null } });
    dispatch({ type: "SET_LOADING", loading: true });
    try {
      props.resendVerificationEmail({
        email: state.formValues.email
      });
    } catch (e) {
      // VerificationCodeForm doesn't render errors the way the other forms do so we have to
      // modify the error result
      const error = Object.fromEntries(
        Object.entries(e).map(([k, v]) => [k, `${k} ${v}`])
      );
      dispatch({ type: "SET_FORM_ERRORS", formErrors: { verification: error } });
    } finally {
      dispatch({ type: "SET_LOADING", loading: false });
    }
  };

  return (
    <VerificationCodeForm
      email={email}
      onSubmit={onSubmit}
      onBack={onBack}
      onResend={onResend}
      formError={state.formErrors.verification}
    />
  );
}

function renderTosForm(props, state, dispatch) {
  const onSubmit = async (values) => {
    dispatch({ type: "SET_LOADING", loading: true });
    try {
      await props.actions.createAccount({
        ...state.formValues,
        tosValues: values,
      });
    } catch (e) {
      dispatch({ type: "SET_FORM_ERRORS", formErrors: { account: e } });
      dispatch({ type: "SET_STEP", step: "account_form" });
    } finally {
      dispatch({ type: "SET_LOADING", loading: false });
    }
  };
  return <TosForm onSubmit={onSubmit} />;
}
CreateAccountWizard.propTypes = {
  actions: PropTypes.shape({
    createAccount: PropTypes.func.isRequired,
  }).isRequired,
  showHeader: PropTypes.bool,
  verificationRequired: PropTypes.bool.isRequired,
};

export default CreateAccountWizard;
